.walkthrough{
    position: relative;
    height: 500px;
    width: 98%;
    margin: 30px auto;
    border-radius: 10px 10px 0px 0px;
    background: rgb(0,29,61);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3vw), 0 100%)
}
.title{
    color:  rgb(255,255,255);
}

.steps{
    display: flex;
    height: 100%;
}
 p { 
    color:  rgb(255,255,255);
}
.step-one, .step-two, .step-three{
    margin: 30px 0px;
    border-radius: 5px;
    width: 100%;

}



.step-one.highlighted{
    background: rgb(0,53,102);
}
.step-two.highlighted{
    background: rgb(0,53,102);
}
.step-three.highlighted{
    background: rgb(0,53,102);
}

.steps img{
    width: 300px;
    height: auto;
    margin: auto;
}
.walkthrough h1, p{
    text-align: center;
}
.rocket-image{
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation: move 4s  linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);

   
}

@media only screen and (max-width: 700px){
    .step-one.highlighted ,.step-two.highlighted, .step-three.highlighted{
        background: rgb(0,29, 61)
    }
    .title{
            font-size: 15px;
    }
    .walkthrough{
        height: 650px;
        clip-path: none;
    }
    .steps{
        flex-direction: column;
        
    }
    .step-one.highlighted, .step-two, .step-three {
        margin: 10px 0px;
        background: rgb(0,53,102);
    }
    .steps img{
        width: 150px;
        height: auto;
        margin: auto;
    }
    .rocket-image{
        visibility: hidden;
    }
}
@keyframes move {
    to {
        bottom: 3vw;
        left: calc(100% - 64px);
    }
}



