.react-datepicker-wrapper, .react-datepicker-wrapper input {
    border-radius: 5px;
    border: 0px solid;
    min-width: 90px;
    width: 5%; 
}

.react-datepicker {
    
    background-color: rgb(255,255,255);
    border: 0px solid;
    border-radius: 5px;
    margin: 10px 10px;

}