.movie-selector{
    position: relative;
    width: 98%;
    height: 70%;
    background: rgb(0,29,61);
    margin: 10px auto;
    border-radius: 10px;

}

.selector-inputs{
    align-items: center;
    display: flex;
}

.datepicker-wrapper {
    min-width: 90px;
    width: 5%; 
}

.selector-inputs .zip-code, .selector-inputs .datepicker-wrapper,
.selector-inputs .search  {
    background-color: rgb(255,255,255);
    border: 0px solid;
    border-radius: 5px;
}
.selector-inputs .zip-code, .selector-inputs .datepicker-wrapper,
.selector-inputs .search-suggested-movies  {
    margin: 10px 10px;
}

.selector-inputs .zip-code, .selector-inputs .radius{
    min-width: 60px;
    width: 5%; 
}
.selector-inputs .zip-code:focus, .selector-inputs .radius:focus,
.selector-inputs .search:focus {
    outline: none;
    box-shadow: 0px 0px 4px  rgb(6,142,255);
}

.search-suggested-movies{
    flex: 1;
    position: relative;
}
.selector-inputs .search {
    width: 100%;
}

.suggested-movies{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    max-height: 500px;
    background: rgb(0,53,102);
    position: absolute;
    z-index: 1;
    border-radius: 5px;
}

.suggested-movie {
    position:relative;
    margin: 10px 10px;
    padding: 0;
    width: 150px;
    height: 225px;
    
}


.selected-movies{
    display: flex;
    flex-wrap: wrap;
    scrollbar-width: thin;
    overflow: auto;
    width: 100%;
    height: 82%;
}

.selected-movie{
    position:relative;
    margin: 10px 10px;
    padding: 0;
    width: 200px;
    height: 300px;
}



.add-movie-tip, .remove-movie-tip{
    background: rgba(0,0,0,0.4);
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 17px;
    color: white;
    width: 100%;
    visibility: hidden;

    

}

.suggested-movie:hover .add-movie-tip, .selected-movie:hover .remove-movie-tip{
    visibility: visible;
}





.movie-selector .submit{
    position: absolute;
    margin: 10px 10px;
    right: 0;
    bottom: 0;
    background-color:  rgb(255,195, 0);
    border: 0px solid;
    border-radius: 5px;
    padding: 4px 15px;
    text-align: center;
}




@media only screen and (max-width: 700px){
    .suggested-movie{
        width: 60px;
        height: 90px;
    }
    .suggested-movies{
        max-height: 350px;
    }
  
    .selected-movie {
        width: 120px;
        height: 180px;
    }
    .add-movie-tip, .remove-movie-tip{
        font-size: 12px;
        visibility: visible;
    }
    
} 

