.result-page{
  display:flex;
  width: 97%;
  height: 100%;
}
.map{
  margin: 0 10px;
  position: relative;
  height: 90vh;
  width: 50%;
}

.result-list{
  margin: 0px 10px;
  overflow: auto;
  scrollbar-width: thin;
  height: 90vh;
  width: 50%;
}
.result{
  position: relative;
  background:  rgb(0,29,61);
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: thin;
  margin: 0px 0px 10px 0px ;
  height: 150px;
  border: 3px solid rgba(0,53,102, 0);
  cursor: pointer;
}

.result:hover{
  border: 3px solid rgb(0,53,102);
}


.cinema-name{
  color: rgb(255,255,255);
  text-align: center;
  height: 40px;
  font-size: 18px;
}
.result-items{
  position: absolute;
  top: 0;
  height:100%;
  display: flex;
  align-items: center;
}
.result-item{
  border: 0px solid black;
  border-radius: 5px;
  margin: 0px 20px;
}
.loading-indicator{
  display:flex;
  margin: 0 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 50%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@media only screen and (max-width: 1300px){
.cinema-name{
  font-size: 13px;
}
.result-item{
  font-size: 13px;
  margin: 0px 20px;
}
} 

@media only screen and (max-width: 700px){
  .result-page{
    display:flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
  }
  .loading-indicator{
    width: 100%;
  }
  .map{
    margin: 0 10px;
    position: relative;
    height: 90vh;
    
  }
  .cinema-name{
    font-size: 12px;
  }
  .result-item{
    font-size: 12px;
    margin: 0px 20px;
  }

  .result-list{
      margin: 0px 10px;
      overflow: auto;
      scrollbar-width: thin;
      height: 65vh;
      width: 100%;

  }
}

