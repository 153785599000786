.nav{
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo{

    margin: 0 1.5rem;
    height: 80%;
    width: auto;

}
.spacer{
    flex: 1;
}
.navItems{
    list-style: none;
    margin: 0 1.5rem;
    padding: 0;
    display: flex;
}
a {
    text-decoration: none;
}

.navItem{
    font-size: 20px;
    margin: 0 1.5rem;
    color:  rgb(255,195, 0);
}

.navItem:hover{
    color: rgb(255,255, 255)
}
