@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
.Navigation_nav__INfuO{
    width: 100%;
    height: 3.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.Navigation_logo__2YMz7{

    margin: 0 1.5rem;
    height: 80%;
    width: auto;

}
.Navigation_spacer__1vJOr{
    -webkit-flex: 1 1;
            flex: 1 1;
}
.Navigation_navItems__2nkdZ{
    list-style: none;
    margin: 0 1.5rem;
    padding: 0;
    display: -webkit-flex;
    display: flex;
}
a {
    text-decoration: none;
}

.Navigation_navItem__1kGJr{
    font-size: 20px;
    margin: 0 1.5rem;
    color:  rgb(255,195, 0);
}

.Navigation_navItem__1kGJr:hover{
    color: rgb(255,255, 255)
}

.MovieSelection_movie-selector__3pkQA{
    position: relative;
    width: 98%;
    height: 70%;
    background: rgb(0,29,61);
    margin: 10px auto;
    border-radius: 10px;

}

.MovieSelection_selector-inputs__34rmX{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}

.MovieSelection_datepicker-wrapper__3HiyL {
    min-width: 90px;
    width: 5%; 
}

.MovieSelection_selector-inputs__34rmX .MovieSelection_zip-code__2bO7t, .MovieSelection_selector-inputs__34rmX .MovieSelection_datepicker-wrapper__3HiyL,
.MovieSelection_selector-inputs__34rmX .MovieSelection_search__3tg1g  {
    background-color: rgb(255,255,255);
    border: 0px solid;
    border-radius: 5px;
}
.MovieSelection_selector-inputs__34rmX .MovieSelection_zip-code__2bO7t, .MovieSelection_selector-inputs__34rmX .MovieSelection_datepicker-wrapper__3HiyL,
.MovieSelection_selector-inputs__34rmX .MovieSelection_search-suggested-movies__1QZ64  {
    margin: 10px 10px;
}

.MovieSelection_selector-inputs__34rmX .MovieSelection_zip-code__2bO7t, .MovieSelection_selector-inputs__34rmX .MovieSelection_radius__XDXOO{
    min-width: 60px;
    width: 5%; 
}
.MovieSelection_selector-inputs__34rmX .MovieSelection_zip-code__2bO7t:focus, .MovieSelection_selector-inputs__34rmX .MovieSelection_radius__XDXOO:focus,
.MovieSelection_selector-inputs__34rmX .MovieSelection_search__3tg1g:focus {
    outline: none;
    box-shadow: 0px 0px 4px  rgb(6,142,255);
}

.MovieSelection_search-suggested-movies__1QZ64{
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
}
.MovieSelection_selector-inputs__34rmX .MovieSelection_search__3tg1g {
    width: 100%;
}

.MovieSelection_suggested-movies__V5YHg{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    max-height: 500px;
    background: rgb(0,53,102);
    position: absolute;
    z-index: 1;
    border-radius: 5px;
}

.MovieSelection_suggested-movie__3VL0b {
    position:relative;
    margin: 10px 10px;
    padding: 0;
    width: 150px;
    height: 225px;
    
}


.MovieSelection_selected-movies__3JhOf{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    scrollbar-width: thin;
    overflow: auto;
    width: 100%;
    height: 82%;
}

.MovieSelection_selected-movie__A8Qxq{
    position:relative;
    margin: 10px 10px;
    padding: 0;
    width: 200px;
    height: 300px;
}



.MovieSelection_add-movie-tip__2tIvt, .MovieSelection_remove-movie-tip__1mAYW{
    background: rgba(0,0,0,0.4);
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 17px;
    color: white;
    width: 100%;
    visibility: hidden;

    

}

.MovieSelection_suggested-movie__3VL0b:hover .MovieSelection_add-movie-tip__2tIvt, .MovieSelection_selected-movie__A8Qxq:hover .MovieSelection_remove-movie-tip__1mAYW{
    visibility: visible;
}





.MovieSelection_movie-selector__3pkQA .MovieSelection_submit__2NUy1{
    position: absolute;
    margin: 10px 10px;
    right: 0;
    bottom: 0;
    background-color:  rgb(255,195, 0);
    border: 0px solid;
    border-radius: 5px;
    padding: 4px 15px;
    text-align: center;
}




@media only screen and (max-width: 700px){
    .MovieSelection_suggested-movie__3VL0b{
        width: 60px;
        height: 90px;
    }
    .MovieSelection_suggested-movies__V5YHg{
        max-height: 350px;
    }
  
    .MovieSelection_selected-movie__A8Qxq {
        width: 120px;
        height: 180px;
    }
    .MovieSelection_add-movie-tip__2tIvt, .MovieSelection_remove-movie-tip__1mAYW{
        font-size: 12px;
        visibility: visible;
    }
    
} 


.react-datepicker-wrapper, .react-datepicker-wrapper input {
    border-radius: 5px;
    border: 0px solid;
    min-width: 90px;
    width: 5%; 
}

.react-datepicker {
    
    background-color: rgb(255,255,255);
    border: 0px solid;
    border-radius: 5px;
    margin: 10px 10px;

}
.Walkthrough_walkthrough__eHoTl{
    position: relative;
    height: 500px;
    width: 98%;
    margin: 30px auto;
    border-radius: 10px 10px 0px 0px;
    background: rgb(0,29,61);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3vw), 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3vw), 0 100%)
}
.Walkthrough_title__3Pns0{
    color:  rgb(255,255,255);
}

.Walkthrough_steps__1NG0T{
    display: -webkit-flex;
    display: flex;
    height: 100%;
}
 p { 
    color:  rgb(255,255,255);
}
.Walkthrough_step-one__2JTYg, .Walkthrough_step-two__1Uw5C, .Walkthrough_step-three__CIru-{
    margin: 30px 0px;
    border-radius: 5px;
    width: 100%;

}



.Walkthrough_step-one__2JTYg.Walkthrough_highlighted__1H6a4{
    background: rgb(0,53,102);
}
.Walkthrough_step-two__1Uw5C.Walkthrough_highlighted__1H6a4{
    background: rgb(0,53,102);
}
.Walkthrough_step-three__CIru-.Walkthrough_highlighted__1H6a4{
    background: rgb(0,53,102);
}

.Walkthrough_steps__1NG0T img{
    width: 300px;
    height: auto;
    margin: auto;
}
.Walkthrough_walkthrough__eHoTl h1, p{
    text-align: center;
}
.Walkthrough_rocket-image__1uoHL{
    position: absolute;
    bottom: 0px;
    left: 0px;
    -webkit-animation: Walkthrough_move__1SQqJ 4s  linear infinite;
            animation: Walkthrough_move__1SQqJ 4s  linear infinite;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
    -webkit-animation-delay: calc(var(--scroll) * -1s);
            animation-delay: calc(var(--scroll) * -1s);

   
}

@media only screen and (max-width: 700px){
    .Walkthrough_step-one__2JTYg.Walkthrough_highlighted__1H6a4 ,.Walkthrough_step-two__1Uw5C.Walkthrough_highlighted__1H6a4, .Walkthrough_step-three__CIru-.Walkthrough_highlighted__1H6a4{
        background: rgb(0,29, 61)
    }
    .Walkthrough_title__3Pns0{
            font-size: 15px;
    }
    .Walkthrough_walkthrough__eHoTl{
        height: 650px;
        -webkit-clip-path: none;
                clip-path: none;
    }
    .Walkthrough_steps__1NG0T{
        -webkit-flex-direction: column;
                flex-direction: column;
        
    }
    .Walkthrough_step-one__2JTYg.Walkthrough_highlighted__1H6a4, .Walkthrough_step-two__1Uw5C, .Walkthrough_step-three__CIru- {
        margin: 10px 0px;
        background: rgb(0,53,102);
    }
    .Walkthrough_steps__1NG0T img{
        width: 150px;
        height: auto;
        margin: auto;
    }
    .Walkthrough_rocket-image__1uoHL{
        visibility: hidden;
    }
}
@-webkit-keyframes Walkthrough_move__1SQqJ {
    to {
        bottom: 3vw;
        left: calc(100% - 64px);
    }
}
@keyframes Walkthrough_move__1SQqJ {
    to {
        bottom: 3vw;
        left: calc(100% - 64px);
    }
}




.Results_result-page__2U2oG{
  display:-webkit-flex;
  display:flex;
  width: 97%;
  height: 100%;
}
.Results_map__1yuvQ{
  margin: 0 10px;
  position: relative;
  height: 90vh;
  width: 50%;
}

.Results_result-list__2V_9L{
  margin: 0px 10px;
  overflow: auto;
  scrollbar-width: thin;
  height: 90vh;
  width: 50%;
}
.Results_result__2MF9Q{
  position: relative;
  background:  rgb(0,29,61);
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: thin;
  margin: 0px 0px 10px 0px ;
  height: 150px;
  border: 3px solid rgba(0,53,102, 0);
  cursor: pointer;
}

.Results_result__2MF9Q:hover{
  border: 3px solid rgb(0,53,102);
}


.Results_cinema-name__1WHjT{
  color: rgb(255,255,255);
  text-align: center;
  height: 40px;
  font-size: 18px;
}
.Results_result-items__cdchn{
  position: absolute;
  top: 0;
  height:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Results_result-item__13ntj{
  border: 0px solid black;
  border-radius: 5px;
  margin: 0px 20px;
}
.Results_loading-indicator__2V6wo{
  display:-webkit-flex;
  display:flex;
  margin: 0 10px;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 90vh;
  width: 50%;
}

.Results_lds-ellipsis__3JeHd {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Results_lds-ellipsis__3JeHd div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Results_lds-ellipsis__3JeHd div:nth-child(1) {
  left: 8px;
  -webkit-animation: Results_lds-ellipsis1__3Mpfm 0.6s infinite;
          animation: Results_lds-ellipsis1__3Mpfm 0.6s infinite;
}
.Results_lds-ellipsis__3JeHd div:nth-child(2) {
  left: 8px;
  -webkit-animation: Results_lds-ellipsis2__3voxo 0.6s infinite;
          animation: Results_lds-ellipsis2__3voxo 0.6s infinite;
}
.Results_lds-ellipsis__3JeHd div:nth-child(3) {
  left: 32px;
  -webkit-animation: Results_lds-ellipsis2__3voxo 0.6s infinite;
          animation: Results_lds-ellipsis2__3voxo 0.6s infinite;
}
.Results_lds-ellipsis__3JeHd div:nth-child(4) {
  left: 56px;
  -webkit-animation: Results_lds-ellipsis3__G6yKN 0.6s infinite;
          animation: Results_lds-ellipsis3__G6yKN 0.6s infinite;
}
@-webkit-keyframes Results_lds-ellipsis1__3Mpfm {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes Results_lds-ellipsis1__3Mpfm {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes Results_lds-ellipsis3__G6yKN {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes Results_lds-ellipsis3__G6yKN {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes Results_lds-ellipsis2__3voxo {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes Results_lds-ellipsis2__3voxo {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}


@media only screen and (max-width: 1300px){
.Results_cinema-name__1WHjT{
  font-size: 13px;
}
.Results_result-item__13ntj{
  font-size: 13px;
  margin: 0px 20px;
}
} 

@media only screen and (max-width: 700px){
  .Results_result-page__2U2oG{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: 100%;
    height: 100%;
  }
  .Results_loading-indicator__2V6wo{
    width: 100%;
  }
  .Results_map__1yuvQ{
    margin: 0 10px;
    position: relative;
    height: 90vh;
    
  }
  .Results_cinema-name__1WHjT{
    font-size: 12px;
  }
  .Results_result-item__13ntj{
    font-size: 12px;
    margin: 0px 20px;
  }

  .Results_result-list__2V_9L{
      margin: 0px 10px;
      overflow: auto;
      scrollbar-width: thin;
      height: 65vh;
      width: 100%;

  }
}


/* Ubuntu font import */
body{
  font-family: 'Ubuntu', sans-serif;
}


/* Have Root take up whole page */
#root{
  height: 100vh;
  min-height: 600px;
}
/* Set background color */
body{
  background-color: rgb(0, 8, 20);
}
/* Remove inline start padding */
*{
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

